import React, { useState, useEffect } from 'react';
import Statistic from '../../components/Statistic';
import config from '../../utils/config';
import netilion from '../../utils/netilion';

const Statistics = () => {
  const [weeklyWatterUsage, setWeeklyWatterUsage] = useState();
  const [dailyWatterUsage, setDailyWatterUsage] = useState();
  const [hourlyWatterUsage, setHourlyWatterUsage] = useState();
  const [weeklyCoffees, setWeeklyCoffees] = useState();
  const [dailyCoffees, setDailyCoffees] = useState();
  const [isLoading, setIsLoading] = useState(true);

  async function fetchData() {
    setWeeklyWatterUsage(await netilion.getWeeklyWatterUsage());
    setDailyWatterUsage(await netilion.getDailyWatterUsage());
    setHourlyWatterUsage(await netilion.getHourlyWatterUsage());
    setWeeklyCoffees(await netilion.getCoffeeConsumptionLastWeek());
    setDailyCoffees(await netilion.getCoffeeConsumptionLastDay());

    setIsLoading(false);
  }

  async function onLoad() {
    await netilion.init();
    fetchData();
    setInterval(() => {
      fetchData();
    }, config.asset.pollRate);
  }

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <div className="mt-8 mx-3">
        <Statistic title="Hourly Water Usage for Making Coffee" isLoading={isLoading} data={hourlyWatterUsage} />
        <Statistic title="Today’s Coffee Consumption" isLoading={isLoading} data={dailyCoffees} span="Today" />
        <Statistic title="Daily Water Usage for Making Coffee" isLoading={isLoading} data={dailyWatterUsage} />
        <Statistic title="This Week‘s Coffee Consumption" isLoading={isLoading} data={weeklyCoffees} span="This week" />
        <Statistic title="Weekly Water Usage for Making Coffee" isLoading={isLoading} data={weeklyWatterUsage} />
      </div>
    </>
  );
};

export default Statistics;
