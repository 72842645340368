import React from "react";
import { MdOpenInNew } from "react-icons/md";
import MessbarImage from "./messbar.png";
import "./Description.css";

const Description = () => {
  const title = "About";
  const text = [
    <li>
      <span>
        An Endress+Hauser&nbsp;
        <a
          href="https://www.endress.com/DMA"
          style={{ width: "fit-content" }}
          className="text-color-e transition duration-200 hover:text-black underline items-center inline-flex"
          target="_blank"
          rel="noopener noreferrer"
        >
          Picomag
          <MdOpenInNew style={{ marginLeft: 5 }} size={15} />
        </a>
        &nbsp;flowmeter provides water consumption data of the messbar coffee machine.
      </span>
    </li>,
    <li>
      <span>
        With the Netilion&nbsp;
        <a
          href="https://www.endress.com/SGC200"
          style={{ width: "fit-content" }}
          className="text-color-e transition duration-200 hover:text-black underline items-center inline-flex"
          target="_blank"
          rel="noopener noreferrer"
        >
          FieldEdge SGC200
          <MdOpenInNew style={{ marginLeft: 5 }} size={15} />
        </a> 
        &nbsp;Bluetooth gateway, the totalizer values are transmitted over Bluetooth
        and 4G Network to the Netilion cloud.
      </span>
    </li>,
    <li>
      <span>
        Values are collected every minute and transmitted to the Netilion Cloud
        every 15 minutes.
      </span>
    </li>,
    <li>
      <span>
        The web application accesses the data over the
        <a
          href="https://developer.netilion.endress.com/netilion-connect"
          style={{ width: "fit-content" }}
          className="text-color-e transition duration-200 hover:text-black underline items-center inline-flex"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Netilion Connect
          <MdOpenInNew style={{ marginLeft: 5 }} size={15} />
        </a>
        &nbsp;Service (JSON REST API) and displays this data in a convenient and
        useful way.
      </span>
    </li>,
  ];

  const styles = {
    img: {
      backgroundImage: `url(${MessbarImage})`,
      backgroundSize: "cover",
      backgroundPosition: "left center",
      height: "40vw",
      width: "100%",
      maxHeight: "35rem",
    },
  };

  return (
    <div className="w-full bg-color-a my-8 p-8" style={styles.about}>
      <h2 className=" font-semibold text-color-e m-0">{title}</h2>
      <ul className="description text-color-e font-normal w-full p-6">
        {text}
      </ul>
      <div
        className="w-full bg-contain bg-no-repeat bg-center mt-4"
        style={styles.img}
      />
    </div>
  );
};

export default Description;
