import React from "react";
import { Link as RouterLink } from "react-router-dom";

function ImprintContent() {
  const title = "Imprint";
  const text = [
    <span className="text-lg">
      This website is provided by Endress+Hauser Process Solutions AG
    </span>,
    <br />,
    <br />,
    "Endress+Hauser Process Solutions AG is a company limited by shares under Swiss law and with its registered seat in Reinach BL / Switzerland. The company is registered in the commercial register of the canton Basel-Landschaft with the business identification number CHE - 105.329.554.",
    <br />,
    <br />,
    "Address: Endress+Hauser Process Solutions AG, Christoph Merian-Ring 4, 4153 Reinach BL, Switzerland",
    <br />,
    "For contact, please use this ",
    <a
      href="https://netilion.endress.com/contact/form"
      className="text-color-e transition duration-200 hover:text-black underline"
    >
      form
    </a>,
    ".",
    <br />,
    <br />,
    "Responsible for content: Managing Director, Rolf Birkhofer. The value added tax number of the Endress+Hauser Process Solutions AG is CHE-105.329.554 MWST. The use of this website is governed by our General Terms of Service which can be found under the menu item ”Terms of Service” at the bottom of the page.",
  ];

  return (
    <div style={{ height: "100%" }} className="w-full my-8 p-8 bg-grey-200">
      <RouterLink
        to="/"
        style={{ color: "#009EE3" }}
        className="text-color-e transition duration-200 hover:text-black text-lg hover:underline"
      >
        &larr; Home
      </RouterLink>
      <br />
      <br />
      <h2 className=" font-semibold text-gray-900 pb-4">{title}</h2>
      <p className=" text-gray-800 font-normal">{text}</p>
      <br />
    </div>
  );
}

export default ImprintContent;
