import React, { useState, useEffect } from "react";
import netilion from "../../utils/netilion";
import config from "../../utils/config";
import "./Header.css";

const Header = () => {
  const title = ["messbar"];
  const location = ["Endress+Hauser Reinach, F1-EG"];

  const styles = {
    header: {
      backgroundColor: "#fff",
      borderBottom: "1px solid #C3CED5",
    },
    title: {
      fontFamily: "EuH Serif Regular",
    }, 
  };

  const latestValueBoxStyles = {
    marginBottom: -15,
    marginRight: 20,
    backgroundColor: "#e2e8f0bc",
    position: "absolute",
    bottom: 0,
    right: 0,
  };
  const [latestValue, setLatestValue] = useState();

  async function fetchData() {
    await netilion.refreshInterval();
    setLatestValue(await netilion.getLatestValue());
  }

  async function onLoad() {
    await netilion.init();
    fetchData();
    setInterval(fetchData, config.asset.pollRate);
  }

  useEffect(() => onLoad(), []);

  return (
    <div style={{ position: "relative" }}>
      <div className="header relative" style={styles.header}>
        <section className="p-8 z-10 relative">
          <h1
            className="font-bold text-4xl text-color-e z-10 relative mr-8 sm:text-5xl mt-0 mb-0"
            style={styles.title}
          >
            {title}
          </h1>
          <h1
            style={{ fontFamily: "EuH Light" }}
            className="text-lg text-color-e z-10 relative mr-8 sm:text-xl mt-0 mb-0"
          >
            {location}
          </h1>
        </section>
      </div>
      <div
        style={latestValueBoxStyles}
        className="inline-block px-3 py-1 ml-8 relative z-10 text-color-e shadow-lg"
      >
        <b>Last Update: (15 min. cycle)</b>
        <br />
        <p className="inline">
          {latestValue
            ? new Date(latestValue.timestamp).toLocaleString()
            : "loading..."}
        </p>
      </div>
    </div>
  );
};

export default Header;
