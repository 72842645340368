/* eslint-disable react/prop-types */
import React from "react";

const Technology = (props) => {
  const { name, picture, link } = props;

  const styles = {
    technology_label: {
      marginTop: "-30px",
    },
  };

  return (
    <div className="bg-color-b m-2 rounded-md w-32 h-40 shadow-sm">
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="block m-auto hover: cursor-pointer h-full w-full"
      >
        <div className="flex content-center justify-center flex-col p-6 pt-0 h-full">
          <img src={picture} alt={name} height="100px" className="" />
        </div>
        <p
          style={styles.technology_label}
          className=" text-color-e font-normal text-center"
        >
          {name}
        </p>
      </a>
    </div>
  );
};

export default Technology;
