import React from 'react';
import Technology from './Technology';

import reactSVG from './media/react.svg';
import expressSVG from './media/express.svg';
import tailwindSVG from './media/tailwind.svg';
import rechartsSVG from './media/recharts.svg';
import eslintSVG from './media/eslint.svg';
import prettierSVG from './media/prettier.svg';
import jestSVG from './media/jest.svg';
import chaiSVG from './media/chai.svg';
import airbnbSVG from './media/airbnb.svg';

const Technologies = () => {
  return (
    <div className="p-8 py-0">
      <h2 className=" font-semibold text-xl text-color-e pb-4 pt-0">
        Made with
        <span role="img" aria-label="heart">
          ❤️
        </span>
        and the following technologies:
      </h2>
      <div className="flex flex-wrap flex-center">
        <Technology name="react" picture={reactSVG} link="https://reactjs.org/" />
        <Technology name="express" picture={expressSVG} link="https://expressjs.com/" />
        <Technology name="tailwind" picture={tailwindSVG} link="https://tailwindcss.com/" />
        <Technology name="recharts" picture={rechartsSVG} link="http://recharts.org/en-US/" />
        <Technology name="eslint" picture={eslintSVG} link="https://eslint.org/" />
        <Technology name="prettier" picture={prettierSVG} link="https://prettier.io/" />
        <Technology name="jest" picture={jestSVG} link="https://jestjs.io/" />
        <Technology name="chai" picture={chaiSVG} link="https://www.chaijs.com/" />
        <Technology name="enzyme" picture={airbnbSVG} link="https://enzymejs.github.io/enzyme/" />
      </div>
    </div>
  );
};

export default Technologies;
