/* eslint-disable react/prop-types */
import React from 'react';
import AreaChart from '../AreaChart';
import Loader from './Loader';

const Statistic = props => {
  const { title, isLoading, data, span = '' } = props;

  const lastUpdate = null;
  let displayData = null;

  if (data && Array.isArray(data.parsed) && data.timestamp) {
    displayData = <AreaChart data={data} />;
  } else if (data && data.timestamp) {
    const coffees = data ? data.coffees : 'no';
    displayData = (
      <div className="flex flex-row justify-start pb-4">
        <div className="flex flex-col mb-2">
          <svg
            className="w-16 h-16 ml-3"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 335.432 335.432"
            width="700px"
            height="700px"
          >
            <g>
              <g>
                <path
                  d="M136.373,113.135c0.998,0.679,2.169,1.038,3.386,1.038h0.014c3.328,0,6.035-2.706,6.035-6.032   c0-0.571-0.079-1.134-0.235-1.68c-3.172-13.899-1.68-25.076,4.436-33.224c7.385-9.837,10.655-22.467,8.748-33.782   c-1.688-10.047-7.089-18.397-15.612-24.143c-2.018-1.365-4.789-1.355-6.806,0.03c-2.054,1.415-3.018,3.901-2.452,6.348   c3.429,14.626,0.302,23.91-7.536,38.008c-4.966,8.933-6.993,18.972-5.708,28.266C122.098,98.499,127.546,107.208,136.373,113.135z"
                  data-original="#000000"
                  className="active-path"
                  data-old_color="#000000"
                  fill="#506671"
                />
                <path
                  d="M193.113,108.673c0.938,0.642,2.035,0.981,3.172,0.981h0.006c3.108,0,5.637-2.529,5.637-5.637   c0-0.52-0.071-1.035-0.214-1.536c-2.236-9.841-1.206-17.713,3.063-23.399c5.621-7.488,8.108-17.114,6.653-25.75   c-1.297-7.712-5.443-14.125-11.991-18.544c-1.881-1.268-4.421-1.283-6.367,0.042c-1.896,1.317-2.805,3.708-2.27,5.917   c2.432,10.369,0.188,16.994-5.423,27.083c-3.776,6.794-5.316,14.438-4.335,21.523C182.161,97.443,186.347,104.132,193.113,108.673z   "
                  data-original="#000000"
                  className="active-path"
                  data-old_color="#000000"
                  fill="#506671"
                />
                <path
                  d="M80.074,108.673c0.938,0.642,2.035,0.981,3.172,0.981h0.006c3.108,0,5.637-2.529,5.637-5.637   c0-0.523-0.072-1.042-0.217-1.544c-2.232-9.837-1.201-17.707,3.065-23.391c5.621-7.488,8.108-17.114,6.653-25.75   c-1.297-7.713-5.444-14.125-11.992-18.544c-1.88-1.268-4.421-1.285-6.366,0.042c-1.896,1.317-2.805,3.708-2.27,5.917   c2.431,10.367,0.188,16.993-5.423,27.083c-3.776,6.794-5.316,14.438-4.335,21.523C69.122,97.443,73.308,104.132,80.074,108.673z"
                  data-original="#000000"
                  className="active-path"
                  data-old_color="#000000"
                  fill="#506671"
                />
                <path
                  d="M330.149,183.855c-3.87-4.556-9.403-7.065-15.581-7.065h-35.794c0.145-0.842,0.297-1.68,0.436-2.525   c0.845-5.154,0.352-12.915-3.819-17.825c-2.589-3.048-6.279-4.66-10.67-4.66H16.389c-6.499,0-10.244,2.799-12.241,5.146   c-4.088,4.808-4.652,12.008-3.816,17.143c4.967,30.517,22.312,105.346,75.958,141.004l0.16,0.106   c3.127,2.081,8.947,5.955,18.88,5.955h90.776c8.189,0,13.223-3.228,15.927-4.962l0.139-0.089   c8.071-5.173,15.636-11.452,22.677-18.781c1.391,0.074,2.791,0.116,4.205,0.116c24.313,0,49.188-10.787,70.046-30.374   c19.245-18.071,32.363-41.139,35.993-63.286C335.546,200.99,336.531,191.369,330.149,183.855z M249.466,263.601   c1.696-3.02,3.346-6.123,4.938-9.327c7.084-14.254,13.073-30.322,17.75-47.484H303.6   C296.409,229.856,274.641,254.37,249.466,263.601z"
                  data-original="#000000"
                  className="active-path"
                  data-old_color="#000000"
                  fill="#506671"
                />
              </g>
            </g>
          </svg>
          <span className="text-white font-bold text-center" style={{ marginTop: '-33px', marginLeft: '18px', width: '40px' }}>{coffees}</span>
        </div>
        <p className=" font-medium pl-4 pt-2 text-color-e">{`${span}, ${coffees} cups of coffee have been made here.`}</p>
      </div>
    );
  } else if(data?.noData) {
    displayData = (
      <div className="flex flex-row justify-start ">
        <svg xmlns="http://www.w3.org/2000/svg" height="512px" viewBox="0 -2 512 512" width="512px" className="w-10 h-10 ml-3">
          <g>
            <path
              d="m448 508.800781-148.59375-92.800781h-267.40625c-17.671875 0-32-14.328125-32-32v-352c0-17.671875 14.328125-32 32-32h448c17.671875 0 32 14.328125 32 32v352c0 17.671875-14.328125 32-32 32h-32zm0 0"
              fill="#506671"
            />
            <g fill="#e2e8f0">
              <path d="m272 256h-32v-144c0-8.835938 7.164062-16 16-16s16 7.164062 16 16zm0 0" />
              <path d="m240 288h32v32h-32zm0 0" fill="#e2e8f0" />
            </g>
          </g>
        </svg>
        <p className=" font-medium pl-4 pt-2 text-color-e">No data</p>
      </div>
    );
  } else {
    displayData = (
      <div className="flex flex-row justify-start ">
        <svg xmlns="http://www.w3.org/2000/svg" height="512px" viewBox="0 -2 512 512" width="512px" className="w-10 h-10 ml-3">
          <g>
            <path
              d="m448 508.800781-148.59375-92.800781h-267.40625c-17.671875 0-32-14.328125-32-32v-352c0-17.671875 14.328125-32 32-32h448c17.671875 0 32 14.328125 32 32v352c0 17.671875-14.328125 32-32 32h-32zm0 0"
              fill="#506671"
            />
            <g fill="#e2e8f0">
              <path d="m272 256h-32v-144c0-8.835938 7.164062-16 16-16s16 7.164062 16 16zm0 0" />
              <path d="m240 288h32v32h-32zm0 0" fill="#e2e8f0" />
            </g>
          </g>
        </svg>
        <p className=" font-medium pl-4 pt-2 text-color-e">Failed to fetch data.</p>
      </div>
    );
  }

  return (
    <>
      <div className="shadow-xl mb-5 p-3" style={{backgroundColor: '#E6ECF0'}}>
        <p className=" font-semibold text-lg text-color-e sm:text-xl">{title}</p>
        {isLoading || !data ? null : <p className=" text-sm text-gray-200 font-light text-color-e pb-4">{lastUpdate}</p>}
        {isLoading || !data ? <Loader /> : displayData}
      </div>
    </>
  );
};

export default Statistic;
