/* eslint-disable react/prop-types */
import React from "react";
import { MdOpenInNew } from "react-icons/md";

const Link = (props) => {
  const { to, newTab, children } = props;

  return (
    <a
      href={to}
      target={newTab ? "_blank" : null}
      rel="noopener noreferrer"
      style={{ width: "fit-content", color: '#fff', fontSize: 18, margin: '15px 0' }}
      className="transition duration-200 hover:underline flex items-center text-lg items-end my-1 inline-block"
    >
      {children}
      {newTab ? (
        <MdOpenInNew style={{ marginLeft: 5 }} fille="#fff" size={15} />
      ) : null}
    </a>
  );
};

export default Link;
