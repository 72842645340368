/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "./Link";
import './Footer.css';

const Footer = () => {
  const styles = {
    footer: {
      backgroundColor: "#00597a",
      color: "#fff",
      padding: "32px 48px",
    },
  };

  return (
    <>
      <div className="w-full p-8 mt-8" style={styles.footer}>
        <label
          style={{ fontSize: 14, marginBottom: 10 }}
          className=" font-semibold text-xl pb-4 pt-0"
        >
          Useful resources
        </label>
        <Link to="https://developer.netilion.endress.com" newTab>
          Netilion Developer Portal
        </Link>
        <Link to="https://www.endress.com/SGC200" newTab>
          Bluetooth FieldEdge SGC200
        </Link>
        <Link to="https://netilion.endress.com/legal/api-terms" newTab>
          API Terms of Service
        </Link>
        <Link to="https://netilion.endress.com/legal/terms-of-service" newTab>
          Terms of Service
        </Link>
      </div>
      <div
        className="footer-copyright w-full px-12 py-4 flex items-center"
        style={{
          backgroundColor: "#00597a",
          borderTop: "1px solid #fff",
        }}
      >
        <label className="text-left" style={{ color: "#f1f2f4", fontSize: 14 }}>
          Copyright © Endress+Hauser Digital Solutions AG
        </label>
        <RouterLink
          to="/imprint"
          style={{
            width: "fit-content",
            color: "#fff",
            fontSize: 14,
            margin: "0 20px",
          }}
          className="transition duration-200 hover:underline flex text-lg items-end my-1"
        >
          Imprint
        </RouterLink>
      </div>
    </>
  );
};

export default Footer;
