import React from 'react';
import ImprintContent from '../components/Imprint';

function Imprint() {
  return (
    <ImprintContent />
  );
}

export default Imprint;
