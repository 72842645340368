import React from 'react';
import ContentLoader from 'react-content-loader';

const MyLoader = () => (
  <ContentLoader speed={2} width="100%" height={150} viewBox="0 0 100% 150px" backgroundColor="#f1f2f4" foregroundColor="#abb3be" className="mt-3">
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="150px" />
  </ContentLoader>
);

export default MyLoader;
