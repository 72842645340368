/* eslint-disable react/prop-types */
import React from 'react';

function CustomTooltip(props) {
  const { active } = props;
  if (active) {
    const { payload } = props;
    return (
      <div className="rounded py-3 px-4 shadow-lg bg-color-a text-color-e flex items-center flex-col">
        <table>
          <tbody>
            <tr>
              <td className="text-right pr-1">
                <p className="inline">{payload[0].payload.timestamp_key}</p>
              </td>
              <td>
                <b>{payload[0].payload.timestamp}</b>
              </td>
            </tr>
            <tr>
              <td className="text-right pr-1">
                <p className="inline">Value:</p>
              </td>
              <td>
                <b>
                  {payload[0].payload.value.toFixed(2)}
                  {' '}
                  l
                </b>
              </td>
            </tr>
            <tr>
              <td className="text-right pr-1">
                <p className="inline">
                  <span role="img">☕</span>
                  :
                </p>
              </td>
              <td>
                <b>{payload[0].payload.cups}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  return null;
}

export default CustomTooltip;