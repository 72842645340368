import React from 'react';
import Statistics from '../containers/Statistics';
import Description from '../components/Description';
import Technologies from '../components/Technologies';

function Home() {
  return (
    <>
      <Statistics />
      <Description />
      <Technologies />
    </>
  );
}

export default Home;
