/* eslint-disable react/prop-types */
import React from 'react';
import { BarChart as Chart, ResponsiveContainer, XAxis, YAxis, Tooltip, Bar } from 'recharts';
import CustomTooltip from './Tooltip';
import './overwrittenStyles.css';

const AreaChart = props => {
  const { data } = props;

  return (
    <ResponsiveContainer width="100%" height={150} className="text-tea">
      <Chart data={data.parsed}>
        <Tooltip content={<CustomTooltip />} />
        <XAxis stroke="#000" dataKey="timestamp" />
        <YAxis stroke="#000" unit="l" />
        <Bar type="monotone" dataKey="value" stroke="#506671" fill="#506671" />
      </Chart>
    </ResponsiveContainer>
  );
};

export default AreaChart;
