import { auth } from '../../axios';
import config from '../../config';

const { request } = auth;
const { username, password, key, secret } = config.netilion;

const convertToToken = res => {
  const { access_token: accessToken, token_type: tokenType, expires_in: expiresIn, refresh_token: refreshToken, created_at: createdAt } = res.data;
  const token = {
    accessToken,
    tokenType,
    refreshToken,
    usableTil: createdAt + expiresIn
  };

  return token;
};

export const requestNewAccessToken = async () => {
  try {
    const res = await request({
      url: '/'
    });

    return res.data;
  } catch (e) {
    throw new Error('Failed to request an access token.');
  }
};

export const refreshAccessToken = async token => {
  try {
    const res = await request({
      url: '/',
      data: JSON.stringify({
        client_id: key,
        client_secret: secret,
        refresh_token: token.refreshToken,
        grant_type: 'refresh_token'
      })
    });

    return convertToToken(res);
  } catch (e) {
    throw new Error('Failed to refresh the access token.');
  }
};
export const revokeAccessToken = async token => {
  try {
    await request({
      url: '/revoke',
      data: JSON.stringify({
        client_id: key,
        client_secret: secret,
        token: token.accessToken
      })
    });
  } catch (e) {
    throw new Error('Failed to revoke the access token.');
  }
};
