import axios from 'axios';
import netilion from '../netilion';
import config from '../config';

const { url, oauthUrl } = config.netilion;

export const auth = axios.create({
  baseURL: `${oauthUrl}/api/token`,
  headers: { 'Content-Type': 'application/json' },
  method: 'GET'
});

export const api = axios.create({
  baseURL: `${url}/v1`,
  method: 'GET'
});

export const basic = axios.create({
  baseURL: `${url}/v1`,
  method: 'GET',
  headers: {
    Authorization: `Basic ${config.netilion.password}`,
    'Api-Key': config.netilion.key,
    'Content-Type': 'application/json'
  }
});
