import EventEmitter from 'events';

import { requestNewAccessToken } from './api/oauth2';
import { api } from '../axios';
import config from '../config';

class Netilion extends EventEmitter {
  constructor() {
    super();
    this.api = api;
    this.assetId = config.asset.id;
    this.token = null;
    this.init();
  }

  async init() {
    this.token = await requestNewAccessToken();

    this.startRefreshInterval();
    this.emit('ready');
  }

  startRefreshInterval() {
    setInterval(this.refreshInterval.bind(this), 600000);
  }

  async refreshInterval() {
    this.token = await requestNewAccessToken(this.token);
  }

  getHeaders() {
    const headers = {
      Authorization: `Basic ${config.netilion.password}`,
      'Content-Type': 'application/json'
    };

    return headers;
  }

  async getCoffeeConsumptionLastWeek() {
    const now = new Date();
    const monday = await this.getMonday(new Date());
    monday.setHours(0, 0, 0, 0);

    const timespan = encodeURI(`from=${monday.toISOString()}&to=${now.toISOString()}`);

    try {
      const response = await this.api.request(`/assets/${this.assetId}/values/pv?per_page=1&${timespan}`, {
        headers: {
          Authorization: `${this.token.tokenType} ${this.token.accessToken}`
        }
      });
      try {
        const coffees = Math.round((response.data.latest - response.data.min) * 8.1);
        return { coffees, timestamp: new Date(response.data.data[0].timestamp).toLocaleString() };
      } catch {
        return { success: null };
      }
    } catch (e) {
      const statusCodeInformation = e.statusCode ? ` Code: ${e.statusCode}` : '';
      throw new Error(`Failed to fetch data.${statusCodeInformation}`);
    }
  }

  async getCoffeeConsumptionLastDay() {
    const now = new Date();
    const beginnOfToday = new Date();
    beginnOfToday.setHours(0, 0, 0, 0);

    const timespan = encodeURI(`from=${beginnOfToday.toISOString()}&to=${now.toISOString()}`);

    try {
      const response = await this.api.request(`/assets/${this.assetId}/values/pv?per_page=1&${timespan}`, {
        headers: {
          Authorization: `${this.token.tokenType} ${this.token.accessToken}`
        }
      });
      try {
        const coffees = Math.round((response.data.latest - response.data.min) * 8.1);
  
        return { coffees, timestamp: new Date(response.data.data[0].timestamp).toLocaleString() };
      } catch {
        return { success: null };
      }
    } catch (e) {
      const statusCodeInformation = e.statusCode ? ` Code: ${e.statusCode}` : '';
      throw new Error(`Failed to fetch data.${statusCodeInformation}`);
    }
  }

  async getMonday(d) {
    let date = new Date(d);
    var day = date.getDay(),
      diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  async getSunday(d) {
    let date = new Date(d);
    var day = date.getDay(),
      diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff - 1));
  }

  async getWeeklyWatterUsage() {
    const monday = await this.getMonday(new Date());
    monday.setDate(new Date().getDate() + 1);
    const fiveWeeksAgo = await this.getSunday(new Date(new Date().setDate(monday.getDate() - 36)));
    const lastSunday = await this.getSunday(new Date());
    const timespan = encodeURI(`from=${fiveWeeksAgo.toISOString()}&to=${lastSunday.toISOString()}`);
    const timespanThisWeek = encodeURI(`from=${lastSunday.toISOString()}&to=${new Date().toISOString()}`);
    try {
      const res = await this.api.request(`/assets/${this.assetId}/values/pv?include=unit&${timespan}&aggregation=max&interval=1d&order_by=timestamp`, {
        headers: {
          Authorization: `${this.token.tokenType} ${this.token.accessToken}`
        }
      });
      const thisWeek = await this.api.request(
        `/assets/${this.assetId}/values/pv?include=unit&${timespanThisWeek}&aggregation=max&interval=1d&order_by=timestamp`,
        {
          headers: {
            Authorization: `${this.token.tokenType} ${this.token.accessToken}`
          }
        }
      );
      if (!res && !res.data) return { error: 'Incorrect response.' };
      try {
        if(res.data.data.length === 0) return { noData: true };
        let baseValue = res.data.data[0].value;
        res.data.data.shift();
        let lastWeek = null;
        let weekValue = 0;
        const onejan = new Date(new Date().getFullYear(), 0, 1);
        const parsedData = [];
        res.data.data.map(el => {
          const timestamp = new Date(el.timestamp);
          const week = Math.ceil(((timestamp - onejan) / 86400000 + onejan.getDay() + 1) / 7) - 1;
          if (week === lastWeek || lastWeek === null) {
            weekValue += el.value - baseValue;
          } else {
            parsedData.push({ timestamp_key: 'Calendar Week', timestamp: week, value: weekValue, cups: Math.round(weekValue * 8.1) });
            weekValue = 0;
          }
          baseValue = el.value;
          lastWeek = week;
        });
        const valueNow = thisWeek.data.data[thisWeek.data.data.length - 1].value;
        const valueLastSunday = thisWeek.data.data[0].value;
        parsedData.push({
          timestamp_key: 'Calendar Week',
          timestamp: lastWeek + 1,
          value: valueNow - valueLastSunday,
          cups: Math.round((valueNow - valueLastSunday) * 8.1)
        });
  
        return { parsed: parsedData, timestamp: new Date(res.data.data[res.data.data.length - 1].timestamp).toLocaleString() };
      } catch (e) {
        return { success: null };
      }
    } catch (e) {
      console.error('Netilion -> if -> e', e);
      const statusCodeInformation = e.statusCode ? ` Code: ${e.statusCode}` : '';
      throw new Error(`Failed to fetch data.${statusCodeInformation}`);
    }
  }

  async getDailyWatterUsage() {
    const now = new Date();
    const eightDaysAgo = new Date();
    eightDaysAgo.setDate(eightDaysAgo.getDate() - 8);
    const timespan = encodeURI(`from=${eightDaysAgo.toISOString()}&to=${now.toISOString()}`);
    try {
      const res = await this.api.request(`/assets/${this.assetId}/values/pv?include=unit&${timespan}&aggregation=max&interval=1d&order_by=timestamp`, {
        headers: {
          Authorization: `${this.token.tokenType} ${this.token.accessToken}`
        }
      });
      if (!res && !res.data) return { error: 'Incorrect response.' };
      try {
        let baseValue = res.data.data[0].value;
        const lastUpdate = res.data.data[res.data.data.length - 1].timestamp;
        res.data.data.shift();
        const parsedData = res.data.data.map(el => {
          const tempData = {
            timestamp_key: 'Date:',
            timestamp: ('0' + new Date(el.timestamp).getDate()).slice(-2) + '.' + ('0' + (new Date(el.timestamp).getMonth() + 1)).slice(-2),
            value: el.value - baseValue < 0 ? 0 : el.value - baseValue,
            cups: Math.round((el.value - baseValue) * 8.1)
          };
          baseValue = el.value < 0 ? 0 : el.value;
          return tempData;
        });
  
        return { parsed: parsedData, timestamp: new Date(res.data.data[res.data.data.length - 1].timestamp).toLocaleString() };
      } catch {
        return { success: null };
      }
    } catch (e) {
      const statusCodeInformation = e.statusCode ? ` Code: ${e.statusCode}` : '';
      throw new Error(`Failed to fetch data.${statusCodeInformation}`);
    }
  }

  async getHourlyWatterUsage() {
    const now = new Date();
    const HoursAgo = new Date();
    HoursAgo.setHours(HoursAgo.getHours() - 25);

    const timespan = encodeURI(`from=${HoursAgo.toISOString()}&to=${now.toISOString()}`);
    try {
      const res = await this.api.request(`/assets/${this.assetId}/values/pv?include=unit&${timespan}&aggregation=max&interval=1h&order_by=timestamp`, {
        headers: {
          Authorization: `${this.token.tokenType} ${this.token.accessToken}`
        }
      });
      if (!res && !res.data) return { error: 'Incorrect response.' };
      try {
        let baseValue = res.data.data[0].value;
        const lastUpdate = res.data.data[res.data.data.length - 1].timestamp;
        res.data.data.shift();
        const parsedData = res.data.data.map(el => {
          const tempData = {
            timestamp_key: 'Time:',
            timestamp: ('0' + new Date(el.timestamp).getHours()).slice(-2) + ':' + ('0' + new Date(el.timestamp).getMinutes()).slice(-2),
            value: el.value - baseValue < 0 ? 0 : el.value - baseValue,
            cups: Math.round((el.value - baseValue) * 8.1)
          };
          baseValue = el.value < 0 ? 0 : el.value;
          return tempData;
        });
  
        return { parsed: parsedData, timestamp: new Date(res.data.data[res.data.data.length - 1].timestamp).toLocaleString() };
      } catch {
        return { success: null };
      }
    } catch (e) {
      const statusCodeInformation = e.statusCode ? ` Code: ${e.statusCode}` : '';
      throw new Error(`Failed to fetch data.${statusCodeInformation}`);
    }
  }

  async getLatestValue() {
    try {
      const res = await this.api.request(`/assets/${this.assetId}/values?key=pv`, {
        headers: {
          Authorization: `${this.token.tokenType} ${this.token.accessToken}`
        }
      });
      return res.data.values.filter(value => value.key === 'pv')[0];
    } catch (e) {
      const statusCodeInformation = e.statusCode ? ` Code: ${e.statusCode}` : '';
      throw new Error(`Failed to fetch data.${statusCodeInformation}`);
    }
  }
}

export default new Netilion();
